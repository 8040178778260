import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../Dashboard/Navbar";
import crossIcon from "../../assets/cross.svg";
import submitLogo from "../../assets/icon-submit.svg";
import cancelLogo from "../../assets/icon-cancel.svg";
import resetLogo from "../../assets/reset.svg";
import loadingGif from "../../assets/loading2.gif";
import addMore from "../../assets/plus-circle.png";
import axios from "axios";
import config from "../Utils/config";
import { useNavigate } from "react-router-dom";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./request.css";
import Popup from "../popupModal/Popup";
import ErrorPopup from "../popupModal/ErrorPopup";
import {v4 as uuidv4} from "uuid";

function Request() {
  const navigate = useNavigate();

  let phoneNum = localStorage.getItem("superonUserPhNum");
  // Imprest functionalty
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [impresDescription, setimpresDescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [message, setMessage] = useState("");

  const [allFiles, setAllFile] = useState("");

  const [itemRequest, setItemRequest] = useState("");

  const [leaveRequest, setLeaveRequest] = useState("");

  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [dealerName, setDealerName] = useState("");
  const [dealerList, setDealerList] = useState([]);
  const [dealerListCopy, setDealerListCopy] = useState([]);
  const [showDealerList, setShowDealerList] = useState(false);

  const [sampleRequest,setSampleRequest] = useState([
    {
      id: uuidv4(),
      item: '',
      dimension: '',
      totalquantity: '',
      quantityperpacket: ''
    }
  ]);
  const [dealerInfo,setDealerInfo] = useState({
    existing: '',
    name: '',
    address: '',
    state: '',
    city: '',
    language: ''
  });

  const [errors,setErrors] = useState({});
  const [responseSampleRequest,setResponseSampleRequest] = useState("");

  const dealerRef = useRef(null);

  const handleDealerName = (dealerName) => {
    console.log(dealerName);
    // console.log(dealerRef.current.innerText);
    setDealerName(dealerName);

    setShowDealerList(false);
  };

  const handleDealerChange = (e) => {
    setDealerName(e.target.value);

    let filteredList = dealerListCopy.filter((item, index) => {
      console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());
      return item.dealername
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase());
    });

    console.log(filteredList);
    setDealerList(filteredList);
  };

  const getDealers = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");

    let res = await axios.get(config.ipAddress + `/dealers/${phoneNum}`);
    console.log(res.data.data);
    let allData = res?.data?.data;
    allData.sort((a, b) => {
      if (a.dealername.toLowerCase() < b.dealername.toLowerCase()) {
        return -1;
      } else if (a.dealername.toLowerCase() > b.dealername.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    setDealerList(allData);
    setDealerListCopy(allData);
  };

  const handleLedgerRequest = async () => {
    let phoneNum = localStorage.getItem("superonUserPhNum");
    if (!(dealerName && selectedDateStart && selectedDateEnd)) {
      setMessage("Please Enter All Details!");
      setShowErrorModal(true);
      return;
    }
    setShowLoading(true);
    const convertDate = (dateStr) => {
      const date = new Date(dateStr);

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    };

    let data = {
      mobile: phoneNum,
      dealername: dealerName,
      datefrom: convertDate(selectedDateStart),
      dateto: convertDate(selectedDateEnd),
    };

    function validateDates(from, to) {
      var fromDate = new Date(from);
      var toDate = new Date(to);

      if (toDate < fromDate) {
        return false;
      }

      return true;
    }
    let isValidate = validateDates(data.datefrom, data.dateto);

    if (!isValidate) {
      // alert("Kindly choose a valid date!");

      setMessage("Kindly choose a valid date!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }

    console.log(data);

    try {
      let response = await axios.post(
        config.ipAddress + `/ledgerrequest`,
        data
      );

      // console.log(response);
      // alert(response.data.message);
      if (response?.data?.message) {
        setIsModalOpen(true);
      }

      setSelectedDateStart("");
      setSelectedDateEnd("");
      setDealerName("");
      setShowLoading(false);
    } catch (error) {
      setMessage("Network error!");
      setShowErrorModal(true);
      setShowLoading(false);
    }
    setShowLoading(false);

    getDealers();
  };
  const resetLedger = () => {
    setSelectedDateStart("");
    setSelectedDateEnd("");
    setDealerName("");
  };
  // blockUser
  useEffect(() => {
    getDealers();
    let phoneNum = localStorage.getItem("superonUserPhNum");

    if (!phoneNum) {
      navigate("/phone");
    }
  }, []);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear().toString().padStart(4, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate); // output: 2023-04-10
    return formattedDate;
  };

  const handleFileUpload = (event) => {
    setFiles([...files, ...event.target.files]);
  };

  const handleRemoveFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
    setCurrentFileIndex(Math.min(currentFileIndex, newFiles.length - 1));
  };

  const handleSelectFile = (fileIndex) => {
    setCurrentFileIndex(fileIndex);
  };

  const submitFiles = async () => {
    // console.log(allFiles);
    if (!files.length) {
      // return alert("Please Choose a file");

      setMessage("Please Upload Odometer Picture!");
      setShowErrorModal(true);
      return;
    }
    if (!impresDescription) {
      // return alert("Please Add Imprest Details!");
      setMessage("Please enter odometer reading!");
      setShowErrorModal(true);
      return;
    }

    setShowLoading(true);
    let form = new FormData();

    for (let i = 0; i < files.length; i++) {
      form.append(`files`, files[i]);
    }

    try {
      let response = await axios({
        method: "post",
        url:
          config.ipAddress +
          `/impressed?mobile=${phoneNum}&comment=${impresDescription}&uploaddate=${getCurrentDate()}`,
        data: form,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      setShowLoading(false);
      console.log(response.data.message);
      // alert(response.data.message);
      if (response?.data?.message) {
        setIsModalOpen(true);
      }
      setimpresDescription("");
      setFiles([]);
      console.log(files, impresDescription);
    } catch (error) {
      setMessage("Network error!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }
  };
  const resetImprest = () => {
    setimpresDescription("");
    setFiles([]);
  };
  // item request functionality

  const handlItemRequest = async () => {
    if (!itemRequest) {
      // return alert("Please Add Items Name!");
      setMessage("Please enter sample/catalogue request!");
      setShowErrorModal(true);
      return;
    }
    setShowLoading(true);
    let data = {
      mobile: phoneNum,
      reason: itemRequest,
    };
    try {
      let response = await axios.post(config.ipAddress + `/item-request`, data);
      console.log(response.data.message);

      // alert(response.data.message);
      setShowLoading(false);
      if (response?.data?.message) {
        setIsModalOpen(true);
      }

      if (response.data.message) {
        setItemRequest("");
      }
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }
  };

  const handleDealerType = (e) => {
    const {name,value} = e.target
    setDealerInfo({
      ...dealerInfo,
      // existing: e.target.value,
      [name]:value
    })
  }
  console.log('dealer type', dealerInfo?.existing);

  const addField = ()=> {
    setSampleRequest([
      ...sampleRequest,
      {
        id: uuidv4(),
        item: '',
        dimension: '',
        totalquantity: '',
        quantityperpacket: ''
      }
    ])
  }
  console.log('sample', sampleRequest)
  const removeField = (id) => {
    setSampleRequest(sampleRequest.filter((request) => {
      return (request.id !== id)
    }))
  }
  const handleSampleRequest = (e,id) => {
    console.log('id',id, sampleRequest)
    const {name,value} = e.target
    const newSampleRequest = sampleRequest.map((item)=>{
      if(item.id === id) {
        return { ...item, [name]:value }
      }
      return item
    })
    console.log('value',e.target.value, newSampleRequest)
    setSampleRequest(newSampleRequest);
  }
  const validateForm = () => {
    let errors = {};
    let showError = false;
    if (!dealerInfo.existing) {
      errors.existing = 'Dealer type is required';
    }
    if (!dealerInfo.name) {
      errors.name = 'Dealer name is required';
    }
    if (!dealerInfo.address) {
      errors.address = 'Dealer address is required';
    }
    if (!dealerInfo.state) {
      errors.state = 'State is required';
    }
    if (!dealerInfo.city) {
      errors.city = 'City is required';
    }
    if (!dealerInfo.language) {
      errors.language = 'Language is required';
    }

    sampleRequest.forEach((item, index) => {
      if (!item.item) {
        showError = true;
        errors[`item_${index}`] = 'Item Description is required';
      }
      if (!item.dimension) {
        showError = true;
        errors[`dimension_${index}`] = 'Item Dimension is required';
      }
      if (!item.totalquantity) {
        showError = true;
        errors[`totalquantity_${index}`] = 'Total Quantity is required';
      }
      if (!item.quantityperpacket) {
        showError = true;
        errors[`quantityperpacket_${index}`] = 'Quantity Per Packet is required';
      }
    });

    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };
  const handleSubmitSampleRequest = async () => {
    if(validateForm()) {
      // const payload = dealerInfo?.map((item,index)=>{
      //   return {...item, mobile:localStorage.getItem('superonUserPhNum'), sample_details:sampleRequest}
      // })
      setShowLoading(true);
      const sampleRequestPayload = sampleRequest?.map((item,index)=> {
        return {...item,mobile:localStorage.getItem('superonUserPhNum')}
      })
      const payload = {
        ...dealerInfo,
        mobile:localStorage.getItem('superonUserPhNum'),
        sample_details: sampleRequestPayload
      }
      console.log('payload', payload)
      
      try {
        let response = await axios.post(config.ipAddress + `/addsamplerequest`, payload)
        console.log('response',response?.data?.message);
        let responseData = response?.data?.message;
        // setResponseSampleRequest(responseData);
        setShowLoading(false);
        
        if (responseData) {
          setIsModalOpen(true);
        }
        

        setDealerInfo({
          existing: '',
          name: '',
          address: '',
          state: '',
          city: '',
          language: ''
        })
        setSampleRequest([
          {
            id: uuidv4(),
            item: '',
            dimension: '',
            totalquantity: '',
            quantityperpacket: ''
          }
        ])
      } catch (error) {
        setMessage("Network Error!");
        setShowErrorModal(true);
        setShowLoading(false);
        return;
      }
    }
    else {
      console.log('Validation failed');
    }
  }
  const resetSampleRequest = () => {
    setDealerInfo({
      existing: '',
      name: '',
      address: '',
      state: '',
      city: '',
      language: ''
    })
    setSampleRequest([
      {
        id: uuidv4(),
        item: '',
        dimension: '',
        totalquantity: '',
        quantityperpacket: ''
      }
    ])
  }
  const resetItemRequest = () => {
    setItemRequest("");
  };

  // leave request functionality

  const handleLeaveRequest = async () => {
    if (!leaveRequest) {
      // return alert("Please describe leave request!");
      setMessage("Please describe leave request!");
      setShowErrorModal(true);
      return;
    }
    setShowLoading(true);
    let data = {
      mobile: phoneNum,
      reason: leaveRequest,
    };

    try {
      let response = await axios.post(config.ipAddress + `/directleave`, data);

      console.log(response.data.message);
      // console.log(leaveRequest);
      // alert(response.data.message);
      setShowLoading(false);
      if (response?.data?.message) {
        setIsModalOpen(true);
      }

      if (response.data.message) {
        setLeaveRequest("");
      }
    } catch (error) {
      setMessage("Network Error!");
      setShowErrorModal(true);
      setShowLoading(false);
      return;
    }
  };

  const resetLeaveRequest = () => {
    setLeaveRequest("");
  };

  return (
    <div className="h-[100dvh] overflow-auto ">
      <div className="sticky top-0 left-0 z-[3]">
        <Navbar />
      </div>

      <div className="p-4 w-full">
        {/* Imprest */}

        {/* <section className="p-4 bg-white rounded-[10px] shadow-6xl border-[1px] border-gray-300">
          
          <div className="flex gap-2 mb-2">
            <div className="bg-[#FF971A] w-[2px] "></div>
            <div className="">
              <h1 className="text-lg ">Add Odometer Picture</h1>
            </div>
          </div>

          <div class="flex items-center justify-center w-full mt-4">
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center w-full  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100"
            >
              <div class="flex flex-col items-center justify-center py-5">
                <svg
                  aria-hidden="true"
                  class="w-8 mb-3 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p class="mb-2 text-sm text-gray-500 ">
                  <span class="font-semibold">Click here to upload</span>
                </p>
                <p class="text-xs text-gray-500 ">JPG or PDF (MAX. 2 MB)</p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                onChange={handleFileUpload}
                // onChange={(e) => setAllFile(e.target.files)}
                multiple
              />
            </label>
          </div>

          {files.length > 0 && (
            <div className="mt-3 ml-2">
              {files.map((file, index) => (
                <div key={file.name} className="flex gap-2">
                  <p
                    onClick={() => handleSelectFile(index)}
                    // className={index === currentFileIndex ? "selected" : ""}
                    className="text-sm"
                  >
                    {file.name}
                 
                  </p>
                  <img
                    src={crossIcon}
                    alt="cross-logo"
                    className="w-4"
                    onClick={handleRemoveFile}
                  />
                </div>
              ))}
            </div>
          )}
       
          <textarea
            type="text"
            className="w-full p-1 px-3 mt-2 border-[1px] text-sm focus:outline-none focus:border-orange-light border-gray-300 rounded-[4px]"
            cols="30"
            rows="2"
            placeholder="Update Odometer Reading"
            value={impresDescription}
            onChange={(e) => setimpresDescription(e.target.value)}
          />
          <div className="text-right  flex justify-end items-center gap-2 mt-2">
    
            <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
              <img
                src={resetLogo}
                alt="submit-logo"
                className="w-4 "
                onClick={() => resetImprest()}
              />
            </div>
            <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
              <img
                src={submitLogo}
                alt="submit-logo"
                className="w-[18px]  "
                onClick={() => submitFiles()}
              />
            </div>
          </div>
      
        </section> */}

        {/* Item-request */}

        <section className="rounded-[10px] ">
          <div className=" bg-white shadow-6xl rounded-[10px] border-[1px] border-gray-300 ">
            {/* <h1 className="text-lg font-semibold ml-[18px] pt-4">
              Customer Ledger
            </h1> */}
            <div className="flex gap-2 mb-2 px-4 pt-4">
              <div className="bg-[#FF971A] w-[2px] "></div>
              <div className="">
                <h1 className="text-lg ">Customer Ledger</h1>
              </div>
            </div>

            <div className=" rounded-sm border-1 border-solid border-gray-400   pb-4 px-4 mt-4">
              <div className=" flex justify-between flex-wrap">
                <div className="w-full mb-4 relative">
                  {/* <select
                    size={1}
                    value={dealerName}
                    onChange={(e) => setDealerName(e.target.value)}
                    className={
                      "flex w-full rounded-md border-solid border-2 box-border  border-gray-300  py-2 px-2 text-sm font-medium overflow-auto  focus:outline-none focus:border-[#FF971A] bg-white"
                    }
                  >
                    <option value="Select Your City" className="">
                      Select Dealer
                    </option>
                    {dealerList.length &&
                      dealerList.map((list, index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={list.dealername}>
                              {list.dealername}
                            </option>
                          </React.Fragment>
                        );
                      })}
                  </select> */}

                  <input
                    type="text"
                    placeholder="Dealer/Customer Name"
                    className="w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none focus:border-orange-light"
                    value={dealerName}
                    onChange={(e) => handleDealerChange(e)}
                    onFocus={() => setShowDealerList(true)}
                    onBlur={(e) => {
                      e.preventDefault();
                      setTimeout(() => {
                        setShowDealerList(false);
                      }, 0);

                      // clearTimeout(timer);
                    }}
                  />
                  {showDealerList && (
                    <div className="w-full absolute top-9 left-0 z-[1] bg-white border-[1px] border-gray-300 border-t-0 min-h-[30px] max-h-[200px] rounded-b-md overflow-y-auto">
                      {dealerList.length ? (
                        dealerList.map((list, index) => {
                          return (
                            <p
                              key={index}
                              ref={dealerRef}
                              onClick={() => handleDealerName(list.dealername)}
                              className="px-3 text-sm py-1 hover:bg-blue-300 "
                            >
                              {list.dealername}
                            </p>
                          );
                        })
                      ) : (
                        <h1 className="text-sm text-center py-1">
                          Delaer not found
                        </h1>
                      )}
                    </div>
                  )}
                </div>

                <div className="w-full flex justify-between gap-3">
                  <Datepicker
                    // portalId="root-portal"
                    popperClassName="some-custom-class1"
                    popperPlacement="bottom"
                    // popperModifiers={[
                    //   {
                    //     name: "offset",
                    //     options: {
                    //       offset: [0, 0],
                    //     },
                    //   },
                    //   {
                    //     name: "preventOverflow",
                    //     options: {
                    //       rootBoundary: "viewport",
                    //       tether: false,
                    //       altAxis: true,
                    //     },
                    //   },
                    // ]}
                    popperModifiers={{
                      flip: { behavior: ["bottom"] },
                      preventOverflow: { enabled: true },
                      hide: { enabled: false },
                    }}
                    selected={selectedDateStart}
                    onChange={(date) => setSelectedDateStart(date)}
                    dateFormat={"yyyy/MM/dd"}
                    placeholderText="From"
                    // showYearDropdown
                    wrapperClassName="datePicker"
                  ></Datepicker>

                  <Datepicker
                    portalId="root-portal"
                    popperClassName="some-custom-class2"
                    // popperModifiers={[
                    //   {
                    //     name: "offset",
                    //     options: {
                    //       offset: [5, 10],
                    //     },
                    //   },
                    //   {
                    //     name: "preventOverflow",
                    //     options: {
                    //       rootBoundary: "viewport",
                    //       tether: false,
                    //       altAxis: true,
                    //     },
                    //   },
                    // ]}
                    popperPlacement="bottom"
                    popperModifiers={{
                      flip: { behavior: ["bottom"] },
                      preventOverflow: { enabled: true },
                      hide: { enabled: false },
                    }}
                    selected={selectedDateEnd}
                    onChange={(date) => setSelectedDateEnd(date)}
                    dateFormat={"yyyy/MM/dd"}
                    placeholderText="To"
                    // showYearDropdown
                    wrapperClassName="datePicker"
                  ></Datepicker>
                </div>
                {/* <input type="text" name="date" placeholder="Select Date" /> */}
              </div>
              {/* <div className="text-right">
                <button
                  onClick={() => handleLedgerRequest()}
                  className="mt-[18px] block rounded-md w-[20%]  bg-[#FF971A] py-2 text-center text-sm font-semibold  text-white "
                >
                  Request
                </button>
              </div> */}
              <div className="flex justify-end items-center gap-2 mt-3">
                {/* <button
                  onClick={() => handleLedgerRequest()}
                  className="rounded-md w-[20%] bg-gray-200 py-2 text-center text-sm  text-black mx-auto"
                >
                  Request
                </button> */}
                <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center ">
                  <img
                    src={resetLogo}
                    alt="submit-logo"
                    className="w-4 "
                    onClick={() => resetLedger()}
                  />
                </div>
                <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
                  <img
                    src={submitLogo}
                    alt="submit-logo"
                    className="w-[18px]  "
                    onClick={() => handleLedgerRequest()}
                  />
                </div>
              </div>
              {/* <hr className="h-[1px] bg-gray-400" /> */}
            </div>
          </div>
        </section>

        <section className="mt-4 bg-white shadow-6xl border-[1px] border-gray-300 p-4 rounded-[10px]">
          {/* <label htmlFor="" className="text-lg font-semibold">
            Item Request
          </label> */}
          <div className="flex gap-2 mb-1 ">
            <div className="bg-[#FF971A] w-[2px] "></div>

            <label htmlFor="" className="text-lg ">
              Catalogue Request
            </label>
          </div>

          <textarea
            type="text"
            className="w-full p-1 px-3 mt-3 border-[1px] text-sm border-gray-300 focus:outline-none focus:border-orange-light rounded-[4px]"
            cols="30"
            rows="5"
            placeholder="Write your Sample/Catalogue request"
            value={itemRequest}
            onChange={(e) => setItemRequest(e.target.value)}
          />

          <div className="flex justify-end items-center gap-2 mt-2">
            {/* <button
              onClick={() => handlItemRequest()}
              className="rounded-md w-[20%] bg-gray-200 py-2 text-center text-sm   text-black mx-auto"
            >
              Submit
            </button> */}
            <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
              <img
                src={resetLogo}
                alt="submit-logo"
                className="w-4 "
                onClick={() => resetItemRequest()}
              />
            </div>
            <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
              <img
                src={submitLogo}
                alt="submit-logo"
                className="w-[18px]  "
                onClick={() => handlItemRequest()}
              />
            </div>
          </div>
          {/* <hr className="h-[1px] bg-gray-400" /> */}
        </section>


        {/* leave request */}
        <section className="mt-4 bg-white shadow-6xl border-[1px] border-gray-300 p-4 rounded-[10px]">
          {/* <label htmlFor="" className="text-lg font-semibold">
            Item Request
          </label> */}
          <div className="flex gap-2 mb-1 ">
            <div className="bg-[#FF971A] w-[2px] "></div>

            <label htmlFor="" className="text-lg ">
              Sample Request
            </label>
          </div>
          <div className="dealer-info">
            <div className="field flex flex-wrap items-center mt-5 mb-5">
              <label
                style={{marginRight: '16px'}}
              >Dealer Type : </label>
              <label
                htmlFor="new"
                style={{marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}
              >
                <input
                    type="radio"
                    class={`w-auto h-[auto]`}
                    style={{marginRight: '8px'}}
                    value="New"
                    name="existing"
                    id="new"
                    checked={dealerInfo?.existing==="New"}
                    onChange={(e)=>{
                      handleDealerType(e)
                      setErrors({...errors, existing: ''})
                    }}
                />
                New
              </label>
              <label
                htmlFor="existing"
                style={{marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}
              >
                <input
                    type="radio"
                    class={`w-auto h-[auto] mr-[2]`}
                    style={{marginRight: '8px'}}
                    value="Existing"
                    name="existing"
                    id="existing"
                    checked={dealerInfo?.existing==="Existing"}
                    onChange={(e) => {
                      handleDealerType(e)
                      setErrors({...errors, existing: ''})
                    }}
                />
                Existing
              </label>
              {errors.existing && <p className="error-field w-[100%] text-[14px]">{errors.existing}</p>}
            </div>
            <div className="field">
              {/* <label>Name</label> */}
              <input
                  type="text"
                  placeholder="Name"
                  class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.name ? 'error-field' : ''}`}
                  // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                  value={dealerInfo?.name}
                  name="name"
                  // onChange={(e)=> {
                  //   handleDealerType
                  //   // handleSampleRequest(e,item.id)
                  //     // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                  // }}
                  onChange={ (e) => {
                    handleDealerType(e)
                    setErrors({...errors, name:''})
                  }}
              />
            </div>
            <div className="field">
              {/* <label>Address</label> */}
              <input
                  type="text"
                  placeholder="Address"
                  class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none  ${errors?.address ? 'error-field' : ''}`}
                  // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                  value={dealerInfo?.address}
                  name="address"
                  // onChange={(e)=> {
                  //   handleDealerType
                  //   // handleSampleRequest(e,item.id)
                  //     // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                  // }}
                  onChange={(e) => {
                    handleDealerType(e)
                    setErrors({...errors, address:''})
                  }}
              />
            </div>
            <div className="field">
              {/* <label>State</label> */}
              <input
                  type="text"
                  placeholder="State"
                  class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.state ? 'error-field' : ''}`}
                  // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                  value={dealerInfo?.state}
                  name="state"
                  onChange={(e) => {
                    handleDealerType(e)
                    setErrors({...errors, state:''})
                  }}
              />
            </div>
            <div className="field">
              {/* <label>City</label> */}
              <input
                  type="text"
                  placeholder="City"
                  class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.city ? 'error-field' : ''}`}
                  // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                  value={dealerInfo?.city}
                  name="city"
                  onChange={(e) => {
                    handleDealerType(e)
                    setErrors({...errors, city: ''})
                  }}
              />
            </div>
            <div className="field">
              {/* <label>Language</label> */}
              <input
                  type="text"
                  placeholder="Language"
                  class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.language ? 'error-field' : ''}`}
                  // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                  value={dealerInfo?.language}
                  name="language"
                  onChange={(e) => {
                    handleDealerType(e)
                    setErrors({...errors, language:''})
                  }}
              />
            </div>
          </div>
          <div className="sample-request">
            {sampleRequest.map((item, index)=>(
              <div key={item?.id} className="form-group relative">
                {sampleRequest.length > 1 && (
                  <button
                    onClick={()=> removeField(item.id)}
                    className="cursor-pointer"
                    style={{position: 'absolute', right: '10px', top: '10px',}}
                  ><img src={crossIcon} alt="Delete" width="16" /></button>
                )}
                <div className="field">
                  <label>Item Description</label>
                  <input
                      type="text"
                      placeholder="Mention Complete Product Description"
                      class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`item_${index}`] ? 'error-field' : ''}`}
                      // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                      value={item?.item}
                      name="item"
                      onChange={(e)=> {
                        handleSampleRequest(e,item.id)
                        setErrors({...errors, [`item_${index}`]: ''})
                          // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                      }}
                  />
                </div>
                <div className="field">
                  <label>Item Dimension</label>
                  <input
                      type="text"
                      placeholder="Mention Complete Product Dimension"
                      class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`dimension_${index}`] ? 'error-field' : ''}`}
                      // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                      value={item?.dimension}
                      name="dimension"
                      onChange={(e)=> {
                        handleSampleRequest(e,item.id)
                        setErrors({...errors, [`dimension_${index}`]: ''})
                          // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                      }}
                  />
                </div>
                <div className="field">
                  <label>Total Quantity Required</label>
                  <input
                      type="text"
                      placeholder="Mention No. of Pcs/Kg"
                      class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`totalquantity_${index}`] ? 'error-field' : ''}`}
                      // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                      value={item?.totalquantity}
                      name="totalquantity"
                      onChange={(e)=> {
                        handleSampleRequest(e,item.id)
                        setErrors({...errors, [`totalquantity_${index}`]: ''})
                          // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                      }}
                  />
                </div>
                <div className="field">
                  <label>Quantity Per Packet</label>
                  <input
                      type="text"
                      placeholder="Type here"
                      class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`quantityperpacket_${index}`] ? 'error-field' : ''}`}
                      // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                      value={item?.quantityperpacket}
                      name="quantityperpacket"
                      onChange={(e)=> {
                        handleSampleRequest(e,item.id)
                        setErrors({...errors, [`quantityperpacket_${index}`]: ''})
                          // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                      }}
                  />
                </div>
              </div>
            ))}
          </div>

          {/* <textarea
            type="text"
            className="w-full p-1 px-3 mt-3 border-[1px] text-sm border-gray-300 focus:outline-none focus:border-orange-light rounded-[4px]"
            cols="30"
            rows="5"
            placeholder="Write your Sample/Catalogue request"
            value={itemRequest}
            onChange={(e) => setItemRequest(e.target.value)}
          /> */}

          <div className="flex justify-end items-center gap-2 mt-2">
            {/* <button
              onClick={() => handlItemRequest()}
              className="rounded-md w-[20%] bg-gray-200 py-2 text-center text-sm   text-black mx-auto"
            >
              Submit
            </button> */}
            <button
              type="button"
              onClick={addField}
              className="px-0 py-0 mt-2"
              style={{marginRight: 'auto'}}
            >
              {/* Add more */}
              <img src={addMore} width={20} alt="" />
            </button>
            {responseSampleRequest ? <span className="text-green-700 text-[14px]">{responseSampleRequest}</span> : ''}
            <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center cursor-pointer">
              <img
                src={resetLogo}
                alt="submit-logo"
                className="w-4 "
                onClick={() => resetSampleRequest()}
              />
            </div>
            <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px] cursor-pointer">
              <img
                src={submitLogo}
                alt="submit-logo"
                className="w-[18px]  "
                onClick={() => handleSubmitSampleRequest()}
              />
            </div>
          </div>
          {/* <hr className="h-[1px] bg-gray-400" /> */}
        </section>


        {/* leave request */}

        <section className="mt-4 bg-white shadow-6xl border-[1px] border-gray-300 p-4 rounded-[10px]">
          <div className="flex gap-2 mb-1">
            <div className="bg-[#FF971A] w-[2px] "></div>

            <label htmlFor="" className="text-lg  ">
              Leave Request
            </label>
          </div>
          {/* <br /> */}
          <textarea
            type="text"
            className="w-full p-1 px-3 mt-3 border-[1px] text-sm border-gray-300 focus:outline-none focus:border-orange-light rounded-[4px]"
            cols="30"
            rows="5"
            placeholder="Write your message here"
            value={leaveRequest}
            onChange={(e) => setLeaveRequest(e.target.value)}
          />
          {/* <textarea name="" id="" cols="30" rows="10"></textarea> */}

          <div className="flex justify-end items-center gap-2 mt-2">
            <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center">
              <img
                src={resetLogo}
                alt="submit-logo"
                className="w-4 "
                onClick={() => resetLeaveRequest()}
              />
            </div>
            <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px]">
              <img
                src={submitLogo}
                alt="submit-logo"
                className="w-[18px]  "
                onClick={() => handleLeaveRequest()}
              />
            </div>
          </div>
          {/* <hr className="h-[1px] bg-gray-400" /> */}
        </section>
      </div>
      {isModalOpen ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <Popup setIsModalOpen={setIsModalOpen} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {isModalOpen ? (
        <div
          onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}

      {showErrorModal ? (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-150px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <ErrorPopup setShowErrorModal={setShowErrorModal} message={message} />

          {/* <button
            className="px-6 py-2 mt-3 bg-red-600 font-semibold text-white rounded-lg "
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button> */}
        </div>
      ) : (
        ""
      )}
      {showErrorModal ? (
        <div
          onClick={() => setShowErrorModal(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Request;
